import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";
import ContactForm from "../components/contactForm";
import Hero from "../components/hero";
import Layout from "../components/layout";
import BackgroundPattern2 from "../images/Behind photo logo.svg";

const CommunicationServicesPage = () => {
  const data = useStaticQuery(graphql`
    query {
      heroImg: wpMediaItem(title: { eq: "Cranfield IT Response Car" }) {
        altText
        localFile {
          publicURL
          childImageSharp {
            original {
              height
              width
            }
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
      Img: wpMediaItem(title: { eq: "laptop-refurb" }) {
        altText
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
      Img2: wpMediaItem(title: { eq: "here-to-help" }) {
        altText
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
      pageData: wpPage(slug: { eq: "sales-services" }) {
        pageFlexibleContent {
          pageFlexibleContent {
            ... on WpPage_Pageflexiblecontent_PageFlexibleContent_InnerPageBanner {
              fieldGroupName
              innerPageBannerHeading
              innerPageBannerSubHeading
              innerPageLink {
                target
                title
                url
              }
              innerPageBannerImage {
                sourceUrl
              }
            }
            ... on WpPage_Pageflexiblecontent_PageFlexibleContent_BasicContentSection {
              basicContent
              fieldGroupName
            }
            ... on WpPage_Pageflexiblecontent_PageFlexibleContent_TwoColumnSection {
              fieldGroupName
              twoColumnContent
              twoColumnFlip
              twoColumnButton {
                target
                title
                url
              }
              twoColumnImage {
                sourceUrl
                localFile {
                  childImageSharp {
                    original {
                      height
                      width
                    }
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  const siteUrl = data.site.siteMetadata.siteUrl;
  // const pluginImage = getImage(data.heroImg.childImageSharp.gatsbyImageData);

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "Communication Services",
        item: {
          url: `${siteUrl}/communication-services`,
          id: `${siteUrl}/communication-services`,
        },
      },
    ],
  };

  const {
    pageData: {
      pageFlexibleContent: { pageFlexibleContent },
    },
  } = data;

  let j = 0;

  const getInTouch = () => {
    let heading = "Get in touch to find out how we can work together";
    if (pageFlexibleContent.length > 0) {
      const reviewSection = pageFlexibleContent.filter(
        (item) =>
          item.fieldGroupName ==
          "Page_Pageflexiblecontent_PageFlexibleContent_GetInTouch"
      );
      if (reviewSection.length > 0) {
        heading = reviewSection[0].getInTouchHeading;
      }
    }
    return (
      <section
        style={{ zIndex: 3 }}
        className="pb-5 py-lg-7 position-relative"
        id="form"
      >
        <Container>
          <Row>
            <Col>
              <h2 className="fs-1 text-center mb-cl-5 mb-4">{heading}</h2>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg={8}>
              <ContactForm />
            </Col>
          </Row>
        </Container>
      </section>
    );
  };

  return (
    <>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <GatsbySeo
        title="Sales | Cranfield IT Solutions"
        description="Explore top-tier refurbished and new computing solutions at Cranfield IT Solutions. Get personalized tech advice to optimize your business or personal needs. Contact us!"
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/communication-services`,
          title: "Sales | Cranfield IT Solutions",
          description:
            "Explore top-tier refurbished and new computing solutions at Cranfield IT Solutions. Get personalized tech advice to optimize your business or personal needs. Contact us!",
          images: [
            {
              url: `${data.heroImg?.localFile.publicURL}`,
              width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
              height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
              alt: `${data.heroImg?.altText}`,
            },
          ],
        }}
      />

      <Layout>
        {pageFlexibleContent.length > 0 &&
          pageFlexibleContent.map((pageInfo, i) => {
            if (
              pageInfo.fieldGroupName ==
              "Page_Pageflexiblecontent_PageFlexibleContent_TwoColumnSection"
            ) {
              j = j + 1;
            }
            return (
              <>
                {pageInfo.fieldGroupName ==
                  "Page_Pageflexiblecontent_PageFlexibleContent_InnerPageBanner" && (
                  <Hero
                    title={
                      <span
                        dangerouslySetInnerHTML={{
                          __html: pageInfo.innerPageBannerHeading,
                        }}
                      ></span>
                    }
                    subHeading={pageInfo.innerPageBannerSubHeading}
                    buttonTarget={pageInfo.innerPageLink?.target}
                    buttonLink={pageInfo.innerPageLink?.url}
                    buttonTitle={pageInfo.innerPageLink?.title}
                    bannerImage={pageInfo.innerPageBannerImage.sourceUrl}
                    hideButton={!pageInfo.innerPageLink ?? true}
                    key={i}
                  />
                )}
                {pageInfo.fieldGroupName ==
                  "Page_Pageflexiblecontent_PageFlexibleContent_TwoColumnSection" &&
                  j == 1 && (
                    <section className=" position-relative ">
                      <Container className=" position-relative py-5 pt-md-7 py-lg-7 pt-xl-5">
                        <div
                          style={{ zIndex: 1 }}
                          className="bg-black position-absolute d-none d-xl-block ps-5 ps-xl-8 text-end top--10 end-0"
                        >
                          <img
                            style={{ maxHeight: "20rem" }}
                            alt=""
                            className=""
                            src={BackgroundPattern2}
                          />
                        </div>
                        <div
                          style={{ zIndex: 1 }}
                          className="bg-black position-absolute d-none d-lg-block d-xl-none ps-5 ps-xl-8 text-end top-10 end-0"
                        >
                          <img
                            style={{ maxHeight: "15rem" }}
                            alt=""
                            className=""
                            src={BackgroundPattern2}
                          />
                        </div>
                        <div
                          style={{ zIndex: 1 }}
                          className="bg-black position-absolute d-md-none w-80 top-0 ps-5 text-end end-0"
                        >
                          <img
                            alt=""
                            style={{ maxHeight: "12rem" }}
                            src={BackgroundPattern2}
                          />
                        </div>
                        <div
                          style={{ zIndex: 1 }}
                          className="bg-black position-absolute d-none d-md-block d-lg-none w-80 top-0 ps-5 text-end end-0"
                        >
                          <img
                            alt=""
                            style={{ maxHeight: "20rem" }}
                            src={BackgroundPattern2}
                          />
                        </div>

                        <Row
                          style={{ zIndex: 3 }}
                          className={`g-3 g-xl-7 position-relative h-100 ${pageInfo.twoColumnFlip === "Flip Yes" ? 'flex-row-reverse' : '' }`}
                        >
                          <Col lg={{ span: 6 }}>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: pageInfo.twoColumnContent,
                              }}
                            />
                          </Col>
                          <Col
                            xs={{ order: "first" }}
                            lg={{ span: 6, order: "last" }}
                          >
                            <div
                              className="position-relative d-none d-lg-block w-90 start-0 bottom-0 mt-lg-5 mt-xl-0  "
                              style={{
                                overflow: "hidden",

                                borderRadius: "8px",
                              }}
                            >
                              <GatsbyImage
                                image={
                                  pageInfo.twoColumnImage.localFile
                                    .childImageSharp.gatsbyImageData
                                }
                                alt={pageInfo.twoColumnImage.altText}
                              />
                            </div>

                            <div
                              className="position-relative  d-lg-none  w-90 w-md-80   "
                              style={{
                                overflow: "hidden",

                                borderRadius: "8px",
                              }}
                            >
                              <GatsbyImage
                                image={
                                  pageInfo.twoColumnImage.localFile
                                    .childImageSharp.gatsbyImageData
                                }
                                alt={pageInfo.twoColumnImage.altText}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row className="mt-4">
                          <Col className="text-center">
                            {" "}
                            {/* <p className="text-start">
                            Contact us today with your requirements.
                          </p> */}
                          
                            <Button
                              as={Link}
                              href={pageInfo.twoColumnButton?.url}
                              rel="noreferrer"
                              variant="primary"
                              className="px-5  mt-4 mb-3 mb-md-0  py-3 w-100 w-md-auto"
                              target={pageInfo.twoColumnButton?.target ?? "_blank"}
                            >
                             {pageInfo.twoColumnButton?.title}
                            </Button>
                          </Col>
                        </Row>
                      </Container>
                    </section>
                  )}
                {pageInfo.fieldGroupName ==
                  "Page_Pageflexiblecontent_PageFlexibleContent_TwoColumnSection" &&
                  j == 2 && (
                    <section className=" position-relative  ">
                      <Container className=" position-relative py-5 pb-lg-7 ">
                        <div
                          style={{ zIndex: 1, left: "6rem" }}
                          className="bg-black position-absolute d-none d-xl-block ps-5 ps-xl-8 text-end top-0 "
                        >
                          <img
                            style={{ maxHeight: "20rem" }}
                            alt=""
                            className=""
                            src={BackgroundPattern2}
                          />
                        </div>
                        <div
                          style={{ zIndex: 1, left: "8rem" }}
                          className="bg-black position-absolute d-none d-lg-block d-xl-none ps-5 ps-xl-8 text-end top-20 "
                        >
                          <img
                            style={{ maxHeight: "15rem" }}
                            alt=""
                            className=""
                            src={BackgroundPattern2}
                          />
                        </div>
                        <div
                          style={{ zIndex: 1 }}
                          className="bg-black position-absolute d-md-none w-50 top-0 text-start start-0"
                        >
                          <img
                            alt=""
                            style={{ maxHeight: "10rem" }}
                            src={BackgroundPattern2}
                          />
                        </div>
                        <div
                          style={{ zIndex: 1 }}
                          className="bg-black position-absolute d-none d-md-inline-block d-lg-none w-80 w-md-auto top-0  text-start start-0"
                        >
                          <img
                            alt=""
                            style={{ maxHeight: "18rem" }}
                            src={BackgroundPattern2}
                          />
                        </div>

                        <Row
                          style={{ zIndex: 3 }}
                          className={`g-3 g-xl-7 position-relative ${pageInfo.twoColumnFlip === "Flip Yes" ? 'flex-row-reverse' : '' }`}
                        >
                          <Col lg={{ span: 6 }}>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: pageInfo.twoColumnContent,
                              }}
                            />
                          </Col>
                          <Col
                            className="d-lg-flex align-items-lg-center align-items-xl-end"
                            lg={{ span: 6 }}
                          >
                            <div
                              className="d-none d-lg-block w-80 mt-lg-7  border border-1 border-primary border-opacity-25"
                              style={{
                                overflow: "hidden",
                                borderRadius: "8px",
                              }}
                            >
                              <GatsbyImage
                                image={
                                  pageInfo.twoColumnImage.localFile
                                    .childImageSharp.gatsbyImageData
                                }
                                alt={pageInfo.twoColumnImage.altText}
                              />
                            </div>

                            <div
                              className="position-relative d-lg-none ms-auto w-90 w-md-80  "
                              style={{
                                overflow: "hidden",
                                borderRadius: "8px",
                              }}
                            >
                              <GatsbyImage
                                image={
                                  pageInfo.twoColumnImage.localFile
                                    .childImageSharp.gatsbyImageData
                                }
                                alt={pageInfo.twoColumnImage.altText}
                              />
                            </div>
                          </Col>
                          
                        </Row>
                        <Row className="mt-4">
                          <Col className="text-center">
                            {" "}
                            <Button
                              as={Link}
                              href={pageInfo.twoColumnButton?.url}
                              rel="noreferrer"
                              variant="primary"
                              className="px-5  mt-4 mb-3 mb-md-0  py-3 w-100 w-md-auto"
                              target={pageInfo.twoColumnButton?.target ?? "_blank"}
                            >
                             {pageInfo.twoColumnButton?.title}
                            </Button>
                          </Col>
                        </Row>
                      </Container>
                    </section>
                  )}
              </>
            );
          })}

        {/* <section className=" pt-md-4 pt-lg-0 pt-xl-5 ">
          <Container>
            <Row>
              <Col>
                <h2 className="fs-1 mb-4">Voice over IP Telephones</h2>
                <p>
                  Our VoIP solutions offer businesses of all sizes reliable,
                  secure, and user-friendly phone systems.
                </p>
                <p>
                  We understand that transitioning to VoIP telephony can be
                  intimidating, which is why we provide comprehensive support
                  and services. With our team of experienced professionals,
                  you'll have expert guidance at every step of the way. What
                  sets our VoIP solutions apart is their flexibility and
                  scalability.
                </p>
                <p>
                  Whether you run a small business or a large enterprise, we
                  have the perfect solution to meet your needs. Additionally,
                  our VoIP solutions are cost-effective, allowing you to save
                  money in the long term.
                </p>
              </Col>
            </Row>
          </Container>
        </section> */}
        {getInTouch()}
      </Layout>
    </>
  );
};

export default CommunicationServicesPage;
